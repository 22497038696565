import axios from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { Children, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import Loading from './Components/Common/Loading';
import PrivateRoute from './Components/Common/PrivateRoute';
import AccessDenied from './Components/ErrorPages/AccessDenied';
import NotFound from './Components/ErrorPages/NotFound';
import loginService from './services/login';
import { history } from './store';
import loginActions from './store/modules/login/actions';
import { types } from './store/modules/login/types';
import { LAYOUT_TYPES, PERMISSIONS, axiosErrorRedirect } from './utils/AppConst';
import { routes } from './utils/Routes';
import SwitchLayout from './Components/Common/SwitchLayout';
import mainActions from "./store/modules/main/actions";
import { hasStudentAndOther, hasOnlyStudent, roleByLayout } from './utils/User';
import fetchTenantData from './utils/fetchTenantData';
import { TenantContextProvider } from './utils/TenantContext';
import usePreventIndexing from './utils/usePreventIndexing';

function App(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const layout = JSON.parse(localStorage.getItem('layout'));
    const [tenant, setTenant] = useState({});
    usePreventIndexing(process.env.REACT_APP_ROBOTS_NOINDEX)

    useEffect(() => {
        axios
            .all([dispatch(loginActions.setUser()), loginService.getUser()])
            .then((d) => {
                const user = JSON.parse(localStorage.getItem("user")) || "";
                dispatch({ type: types.LOGIN_SUCCESS, user: d });
                props.selectLayout(layout);
                if (hasOnlyStudent()) {
                    localStorage.setItem('layout', JSON.stringify(LAYOUT_TYPES.STUDENT));
                    props.selectLayout(LAYOUT_TYPES.STUDENT);
                    props.setRoles([PERMISSIONS.STUDENT]);
                } else if (hasStudentAndOther()) {
                    props.setRoles(roleByLayout(user, layout));
                } else {
                    localStorage.setItem('layout', JSON.stringify(LAYOUT_TYPES.ADMIN));
                    props.selectLayout(LAYOUT_TYPES.ADMIN);
                    props.setRoles(user.roles.filter((element) => element !== PERMISSIONS.STUDENT));
                }
                setLoading(false);
            })
            .catch((error) => {
                axiosErrorRedirect(error);
            })
    }, []);

    useEffect(() => {
        fetchTenantData().then((data) => {
            setTenant(data);
        })
        .catch((error) => Promise.reject(error));
    }, []);

    if (loading) return <Loading />;

    return (
        <TenantContextProvider tenant={tenant}>
            <ConnectedRouter history={history}>
                <Switch>
                    {
                        hasStudentAndOther() && !props.layout ?
                        (
                            <Route path='/' component={SwitchLayout}/>
                        ) :
                        (
                            Children.toArray(
                                routes.map((route) => (
                                    <PrivateRoute
                                        path={route.path}
                                        exact
                                        component={route.component}
                                        roles={route.roles}
                                    />
                                ))
                            )
                        )
                    }
                    <Route
                        path='/login'
                        exact
                        component={() => {
                            window.location.href =
                                process.env.REACT_APP_AUTH_URL ||
                                'https://auth01.mcc.hu';
                        }}
                    />
                    <Route path='/switch-layout' component={SwitchLayout}/>
                    <Route path='/404' component={NotFound} />
                    <Route path='/403' component={AccessDenied} />
                    <Route component={NotFound} />
                </Switch>
            </ConnectedRouter>
        </TenantContextProvider>
    );
}

function mapState(state) {
    const { layout } = state.main;
    return { layout };
}

const actionCreators = {
    selectLayout: mainActions.selectLayout,
    setRoles: loginActions.setRoles,
};

export default connect(mapState, actionCreators)(App);
